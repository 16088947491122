import router from "@/router";
import { BaseApiService } from "./ApiBaseService";

export class AuthService extends BaseApiService{
    
    checkSession(): boolean {
        let token = this.getItem('token');
        let userData = JSON.parse(this.getItem('userData') ?? '{}');
        let valid = (Object.keys(userData).length > 0 && token != null);

        if(!valid) this.clearSession();
        return valid;
    }

    clearSession() {
        window.sessionStorage.clear();
    }

    getItem(key: string) {
        return window.sessionStorage.getItem(key);
    }

    setItem(key: string, value: any) {
        window.sessionStorage.setItem(key, value);
    }

    async login(data: any) {
        return await this.post("/login", data)
    }

    async signup(data: any) {
        return await this.post("/signup", data);
    }

    logout() {
        this.clearSession();
        router.push("/login");
    }
}