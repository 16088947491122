import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from 'vue-router'
import Login from '@/views/Login/Login.vue'
import UserList from '@/views/UserList/UserList.vue'
import Profile from '@/views/Profile/Profile.vue'
import { AuthService } from '@/services/AuthService'

const auth = new AuthService();

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: {name: 'login'}
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/user-list',
    name: 'user-list',
    component: UserList,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/profile/:id',
    name: 'profile',
    component: Profile,
    meta: {
      requiresAuth: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (!auth.checkSession()) {
      return ({
        name: "login",
        replace: true,
        force: true
      })
    } 
  } 
})

export default router
