<template>
  <div class="login-page page">
    <div>
      <div class="wrapper">
        <img :src="'./assets/images/logo.png'" alt="" />
        <div class="wrapper-content">
          <div class="page-type-options">
            <button
              @click="pageType = 'log-in'"
              :class="{ active: pageType == 'log-in' }"
            >
              Log In
            </button>
            <button
              @click="pageType = 'sign-up'"
              :class="{ active: pageType == 'sign-up' }"
            >
              Sign Up
            </button>
          </div>
          <div class="form-container">
            <FormText type="text" placeholder="email" v-model="loginData.email"/>
            <template v-if="pageType == 'sign-up'">
              <div class="form-group">
                <FormText type="text" placeholder="first name" v-model="signupData.firstName"/>
                <FormText type="text" placeholder="last name" v-model="signupData.lastName"/>
              </div>
            </template>
            <FormText type="password" placeholder="password" v-model="loginData.password"/>
            <FormText
              type="password"
              placeholder="confirm password"
              v-model="signupData.confirmPassword"
              v-if="pageType == 'sign-up'"
            />
          </div>
        </div>
        <div class="wrapper-footer">
          <button @click="submit" :class="{ 'sign-up': pageType == 'sign-up' }">
            {{ pageType == "sign-up" ? "Sign Up" : "Log In" }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style src="../../components/Form/FormBase.scss" lang="scss"></style>
<style src="./Login.scss" lang="scss" scoped></style>
<script src="./Login.ts"></script>
