import { defineComponent, onMounted, reactive } from 'vue';
import { BaseApiService } from '@/services/ApiBaseService';
import NavBar from '@/components/NavBar/NavBar.vue';
import { UserApiService } from '@/services/ApiUserService';

export default defineComponent({
  // type inference enabled
  components: {
    NavBar
  },
  props: {
  },
  setup(props) {
    const data = reactive({
      list: [
        {
          firstName: "First Name", 
          lastName: "Last Name", 
          email: "Email", 
          phoneNo: "Phone No.", 
          dob: "Date of Birth", 
          address: "Address"}
      ]
    })
    onMounted(async () => {
      let tempData = await new UserApiService().getAllUsers();

      data.list.push(...tempData);
      console.log(data.list);
    })

    return {
      data
    }
  },
  data() {
    return {
      
    }
  },
  methods: {
  }
  
})