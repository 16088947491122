<template>
    <div class="user-list-page page">
      <NavBar />
      <div class="page-content">
        <p class="page-title">user list</p>
        <div class="list">
          <template v-for="(item, index) in data.list">
            <p :class="{'header': index == 0}">{{ item.firstName }}</p>
            <p :class="{'header': index == 0}">{{ item.lastName }}</p>
            <p :class="{'header': index == 0}">{{ item.email }}</p>
            <p :class="{'header': index == 0}">{{ item.phoneNo }}</p>
            <p :class="{'header': index == 0}">{{ item.dob }}</p>
            <p :class="{'header': index == 0}">{{ item.address }}</p>
          </template>
        </div>
      </div>
    </div>
  </template>
  
  <style src="./UserList.scss" lang="scss" scoped></style>
  <script src="./UserList.ts"></script>