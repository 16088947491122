import { defineComponent, onMounted, reactive } from 'vue';
import { BaseApiService } from '@/services/ApiBaseService';
import { UserApiService } from '@/services/ApiUserService';
import { AuthService } from '@/services/AuthService';

export default defineComponent({
  // type inference enabled
  props: {
  },
  setup(props) {
    const auth = new AuthService();
    const userDetails = JSON.parse(auth.getItem('userData') ?? "{}");
    const assetPath = process.env.VUE_APP_ASSET_PATH

    const logout = () => {
      auth.logout();
    }

    return {
      userDetails,
      assetPath,
      logout
    }
  },
  data() {
    return {
    }
  },
  methods: {
  }
  
})