
import { ref, defineComponent } from 'vue';
import FormText from '@/components/Form/FormText/FormText.vue';
import { BaseApiService } from '@/services/ApiBaseService';
import router from '@/router';
import { AuthService } from '@/services/AuthService';

export default defineComponent({
  // type inference enabled
  props: {
    message: String
  },
  components: {
    FormText
  },
  setup(props) {
    props.message // type: string | undefined
  },
  data() {
    return {
      pageType: "log-in" as string,
      loginData: {
        email: '' as string,
        password: '' as string
      } as any,
      signupData: {
        firstName: '' as string,
        lastName: '' as string,
        confirmPassword: '' as string
      }as any,
      auth: new AuthService()
    }
  },
  computed: {
    pageTypeName() {
      return this.pageType.replace('-', ' ');
    }
  },
  methods: {
    async submit() {
      this.auth.clearSession();
      let data = (this.pageType == 'log-in') ? {...this.loginData} : {...this.loginData, ...this.signupData};
      if(this.pageType == 'sign-up') {
        if(this.loginData.password.trim() != this.signupData.confirmPassword.trim() 
          && this.loginData.password.trim().length < 8) return;
        delete data.confirmPassword;
      }

      let response = (this.pageType == 'log-in') ? await this.auth.login(data): await this.auth.signup(data);
      if(response.data.success && response.status === 200) {
        this.auth.setItem('token', response.data.token);
        this.auth.setItem('userData', JSON.stringify(response.data.userData));
        router.push({path: '/profile/' + response.data.userData.id});
      }
    }
  }
  
})
