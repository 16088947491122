import { BaseApiService } from "./ApiBaseService";
import { AuthService } from "./AuthService";

export class UserApiService extends BaseApiService {
    private auth = new AuthService();

    async getAllUsers() {
        let response = await this.get("/users");
        if(response.data.success  && response.status === 200) return response.data.results;
    }

    async getUserById(id: number) {
        let response = await this.get("/users/" + id);
        if(response.data.success  && response.status === 200) return response.data.results;
        this.auth.logout();
    }

    async updateUserData(data: any) {
        data["id"] = JSON.parse(this.auth.getItem('userData')!).id;
        let response = await this.post("/users/update", data);
        console.log(response);
        return (response.data.success  && response.status === 200);
    }
}