import axios, { AxiosInstance } from "axios";

export class BaseApiService {
  protected async init(): Promise<AxiosInstance> {
    let headers: any = {
      "Access-Control-Allow-Origin": process.env.VUE_APP_API_PATH
    }

    let token = window.sessionStorage.getItem('token');

    if(token) {
      headers["token"] = token;
    }

    return axios.create({
      baseURL: process.env.VUE_APP_API_PATH,
      headers
    });
  }

  protected async get(path: string, data: any = {}) {
    try {
      return await (await this.init()).get(path, { data });
    } catch (e: any) {
      return e;
    }
  }

  protected async post(path: string, data: any = {}) {
    try {
      return await (await this.init()).post(path, data);
    } catch (e: any) {
      return e;
    }
  }
}
