<template>
    <div class="nav">
        <div class="wrapper">
            <div class="logo-container">
                <img :src="`${assetPath}images/logo.png`" alt="">
            </div>
            <router-link class="link-profile" :to="'/profile/' + userDetails.id">
                <span class="image-container">
                    <i class="fa-solid fa-user"></i>
                </span>
                <span class="name">{{ userDetails.firstName + " " + userDetails.lastName }}</span>
            </router-link>
            <button class="logout" @click="logout">Log out</button>
            <div class="links">
                <router-link to="/user-list">User List</router-link>
            </div>
        </div>
    </div>
</template>

<style src="./NavBar.scss" lang="scss" scoped></style>
<script src="./NavBar.ts"></script>