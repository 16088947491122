import { reactive, defineComponent, onMounted } from 'vue';
import { BaseApiService } from '@/services/ApiBaseService';
import NavBar from '@/components/NavBar/NavBar.vue';
import FormText from '@/components/Form/FormText/FormText.vue';
import { UserApiService } from '@/services/ApiUserService';
import { useRoute } from 'vue-router';

export default defineComponent({
  // type inference enabled
  components: {
    NavBar,
    FormText
  },
  data() {
    return {
      edit: false as boolean,
    }
  },
  props: {
  },
  setup(props) {
    const route = useRoute();

    const data = reactive({
      defaultData: {
        email: "",
        first_name: "",
        last_name: "",
        phone_no: "",
        address: "",
        dob: ""
      },
      mutableData:{
        email: "",
        first_name: "",
        last_name: "",
        phone_no: "",
        address: "",
        dob: ""
      }
    });

    onMounted(async () => { 
      console.log(route.params);
      data.mutableData = await new UserApiService().getUserById(parseInt(route.params.id as string));
      data.defaultData = {...data.mutableData};
    })

    return {
      data,
    }
  },
  
  methods: {
    onEditButtonClick() {
      if(this.edit) {
        this.cancelChanges();
      }else {
        this.edit = !this.edit;
      }
    },
    async updateChanges() {
      if(await new UserApiService().updateUserData({...this.data.mutableData})) {
        this.data.defaultData = {...this.data.mutableData};
        this.edit = false;
      }else {
        this.cancelChanges();
      }
    },
    cancelChanges() {
      this.data.mutableData = {...this.data.defaultData};
      this.edit = false;
    }
  }
})