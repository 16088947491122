<template>
  <div :class="'form-item ' + type">
    <label v-if="checkLabel"><span>{{ label }}</span><span class="colon">:</span></label>
    <div class="wrapper" :class="{'to-text': toText}">
      <template v-if="!toText">
        <input 
          :value="modelValue" 
          :type="inputType" 
          :placeholder="placeholder" 
          @input="$emit('update:modelValue', $event.target.value)"
        />
        <button
          v-if="type == 'password'"
          @click="inputType = inputType == 'password' ? 'text' : 'password'"
        >
          <i
            :class="
              'fa-solid ' + (inputType == 'password' ? 'fa-eye-slash' : 'fa-eye')
            "
          ></i>
        </button>
      </template>
      <template v-else>
        <p>{{ modelValue }}</p>
      </template>
    </div>
  </div>
</template>

<style src="./FormText.scss" lang="scss" scoped></style>
<script src="./FormText.ts"></script>
