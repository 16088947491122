
import { defineComponent } from 'vue';

export default defineComponent({
  // type inference enabled
  props: {
    modelValue: String,
    type: {
      type: String,
      default: "text"
    },
    toText: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    },
    toTextNoLabel: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ""
    },
  },
  setup(props) {
    props.type;
    props.label;
    props.placeholder;
    props.modelValue;
    props.toText;
    props.toTextNoLabel;
  },
  data() {
    return {
        inputType: this.type ?? "text" as string
    }
  },
  computed:{
    checkLabel() {
      if(!this.label) return false;
      if(this.toTextNoLabel) {
        return (!this.toText);
      }else {
        return true;
      }
    }
  },
})