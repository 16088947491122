<template>
  <div class="profile-page page">
    <NavBar />
    <div class="page-content" v-if="data.mutableData">
        <div class="basics">
          <button class="edit" @click="onEditButtonClick">
            <i class="fa-solid fa-pen"></i>
          </button>
          <div class="image-container">
            <i class="fa-solid fa-user"></i>
          </div>
          <div class="form-group">
            <FormText
              label="First Name"
              :toText="!edit"
              v-model="data.mutableData.first_name"
              :toTextNoLabel="true"
            />
            <FormText
              label="Last Name"
              :toText="!edit"
              v-model="data.mutableData.last_name"
              :toTextNoLabel="true"
            />
          </div>
        </div>
        <div class="details">
          <FormText
            label="Email"
            :toText="!edit"
            v-model="data.mutableData.email"
          />
          
          <FormText
            label="Phone No."
            :toText="!edit"
            v-model="data.mutableData.phone_no"
          />
          <FormText
            label="Date of Birth"
            :toText="!edit"
            v-model="data.mutableData.dob"
          />
          <FormText
            label="Address"
            :toText="!edit"
            v-model="data.mutableData.address"
          />
          <div class="form-group" v-if="edit">
            <button @click="updateChanges">Update Changes</button>
            <button @click="cancelChanges">Cancel</button>
          </div>
        </div>
    </div>
  </div>
</template>

<style src="../../components/Form/FormBase.scss" lang="scss"></style>
<style src="./Profile.scss" lang="scss" scoped></style>
<script src="./Profile.ts"></script>
